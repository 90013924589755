import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { SanityAboutType } from '../utils/globalTypes';
import {
  AboutFirstSection,
  AboutSecondSection,
  AboutThirdSection,
  AboutFourthSection,
  SEO,
  HomeCareer,
  AboutSixthSection,
} from '../components';
import { SEO_TEXT } from '../constants';
import { getClient } from '../utils/sanity.client';
import { aboutQuery } from '../utils/querys/about/query';

interface Props {
  data: {
    sanityAbout: SanityAboutType;
  };
}

const AboutPage = ({ data: { sanityAbout } }: Props) => {
  const [aboutData, setAboutData] = useState<any>();

  useEffect(() => {
    const url = new URL(location.href);
    const getData = async () => {
      const client = getClient({ token: process.env.GATSBY_SANITY_READ_TOKEN || '' });
      const data = await client.fetch(aboutQuery);
      setAboutData(data);
    };
    if (aboutData === undefined && url.search === '?preview_mode=true') getData();
  }, [aboutData]);
  return (
    <Fragment>
      <SEO title={sanityAbout.seo?.title || ''} description={sanityAbout.seo?.description || ''} />
      <AboutFirstSection content={aboutData ? aboutData.firstSection : sanityAbout.firstSection} />
      <AboutSecondSection content={aboutData ? aboutData.secondSection : sanityAbout.secondSection} />
      <AboutThirdSection content={aboutData ? aboutData.thirdSection : sanityAbout.thirdSection} />
      <AboutFourthSection content={aboutData ? aboutData.fourthSection : sanityAbout.fourthSection} />
      <div className="about-testimonial-section">
        <HomeCareer content={aboutData ? aboutData.fifthSection : sanityAbout.fifthSection} />
        <AboutSixthSection content={aboutData ? aboutData.sixthSection : sanityAbout.sixthSection} />
      </div>
    </Fragment>
  );
};

export default AboutPage;
export const pageQuery = graphql`
  query {
    sanityAbout {
      seo {
        title
        keywords
        description
      }
      firstSection {
        title
        description
        aboutUsImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
      }
      secondSection {
        title
        differentiators {
          title
          description
          listIcon {
            asset {
              gatsbyImageData(fit: SCALE)
            }
          }
        }
      }
      thirdSection {
        statistics {
          label
          value
        }
      }
      fourthSection {
        title
        description
        aboutAvatars {
          role
          name
          avatarImg {
            asset {
              gatsbyImageData(fit: CROP)
            }
          }
          _rawBio
        }
      }
      fifthSection {
        provider
        quotation
      }
      sixthSection {
        teamImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
      }
    }
  }
`;
