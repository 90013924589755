export const aboutQuery = `// groq
*[_type == 'about'][0] {
  seo {
    title,
    keywords,
    description
  },
  firstSection {
    title,
    description,
    aboutUsImage {
      asset-> {
        url
      }
    }
  },
  secondSection {
    title,
    differentiators[] {
      title,
      description,
      listIcon {
        asset-> {
          url
        }
      }
    }
  },
  thirdSection {
    statistics[] {
      label,
      value
    }
  },
  fourthSection {
    title,
    description,
    aboutAvatars[] {
      role,
      name,
      avatarImg {
        asset-> {
          url
        }
      },
      "_rawBio": bio
    }
  },
  fifthSection {
    provider,
    quotation
  },
  sixthSection {
    teamImage {
      asset-> {
        url
      }
    }
  }
}

`;
